import { CssBaseline } from '@mui/material';
import ResponsiveAppBar from '../navbar/ResponsiveNavbar';
import './UnderConstruction.css';

const video = require("../../assets/himiko-laying.mp4");

const UnderConstruction = () => {
    return (
        <div className="uc-container">
            <ResponsiveAppBar />
            <CssBaseline />

            <div className="uc-content">
                <video className="uc-video-background" playsInline autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support playing videos :(
                </video>

                <main className="uc-main">
                    <h1 className="uc-header">🛠️ Under Construction 🛠️</h1>
                    <p className="uc-subheader">Please excuse our dust 🧹💨</p>
                </main>

                <footer className="uc-footer">
                    <p className="uc-discord-contact"><span className="uc-discord-icon"><i className='fab fa-discord'></i></span> NeonDodongo</p>
                    <p>&copy; 2024</p>
                </footer>
            </div>
        </div>
    )
}

export default UnderConstruction;
import React from 'react';
import Landing from '../components/landing/Landing';
import UnderConstruction from '../components/under-construction/UnderConstruction';
import { isProdMode } from '../util/AppSettings';

const Home = () => {
    if (isProdMode) {
        return <UnderConstruction/>  
    }

    return <Landing/>  
}

export default Home;
import { createTheme } from '@mui/material/styles';

export const ArksbookTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffa3a3',
    },
    secondary: {
      main: '#f50057',
    },
  },
});
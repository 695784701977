import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Avatar, Link, Tooltip } from '@mui/material';
import { isDevMode } from '../../util/AppSettings';
import { useAuth } from '../auth/AuthContext';


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  // TODO: Get session cookie and determine if user is already logged in
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  }

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!isLoggedIn) {
//         navigate('/')
//     }
//   }, [isLoggedIn, navigate])

  return (
        <AppBar position="static" color="primary">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'white',
                    textDecoration: 'none',
                    }}
                >
                    ARKSBOOK
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                    <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <MenuItem key='Apply'>
                            <Link href='https://tinyurl.com/arksbook-application' target='_blank' rel='noreferrer' underline='none'>
                                <Typography sx={{ textAlign: 'center', display: 'inline', color: 'black'}}>
                                    Apply <OpenInNewIcon sx={{ float: 'right', mr: 1, fontSize: '14px'}}></OpenInNewIcon>
                                </Typography>
                            </Link>
                        </MenuItem>
                        <MenuItem key='Discord'>
                            <Link href='https://discord.gg/8QePyvhcrM' target='_blank' rel='noreferrer' underline='none'>
                                <Typography sx={{ textAlign: 'center', display: 'inline', color: 'black'}}>
                                    Discord <OpenInNewIcon sx={{ float: 'right', mr: 1, fontSize: '14px' }}></OpenInNewIcon>
                                </Typography>
                            </Link>
                        </MenuItem>
                    </Menu>
                </Box>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'white',
                    textDecoration: 'none',
                    }}
                >
                    ARKSBOOK
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, flexDirection: 'row', justifyContent:'right', alignItems: 'center'}}>
                    <Button
                        href='https://tinyurl.com/arksbook-application' 
                        target='_blank' 
                        rel='noreferrer'
                        key='Apply'
                        sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                        Apply <OpenInNewIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, float: 'right', fontSize: '14px'}}></OpenInNewIcon>
                    </Button>
                    <Button
                        href='https://discord.gg/8QePyvhcrM' 
                        target='_blank' 
                        rel='noreferrer'
                        key='Discord'
                        sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                        Discord <OpenInNewIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, float: 'right', fontSize: '14px'}}></OpenInNewIcon>
                    </Button>
                </Box>
                {isDevMode && (
                    isLoggedIn ? (
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key='Logout' onClick={handleLogout}>
                                    <Typography sx={{ textAlign: 'center' }}>Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <Button
                                href='/login' 
                                key='Login'
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Login 
                            </Button>
                            <Typography sx={{ textAlign: 'center', color: 'white' }}>|</Typography>
                            <Button
                                href='/register' 
                                key='Register'
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Register 
                            </Button>
                        </Box>
                    )
                )}
                </Toolbar>
            </Container>
        </AppBar>
  );
}
export default ResponsiveAppBar;
import ResponsiveAppBar from '../navbar/ResponsiveNavbar';
import './NotFound.css';

const NotFound = () => (
    <main>
        <ResponsiveAppBar />
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>Doko?</h1>
                </div>
                <h2>404 - Page not found</h2>
                <p>We were unable to find what you were looking for :(</p>
                <a href="/">Go To Homepage</a>
            </div>
        </div>
    </main>

);

export default NotFound;
import './App.css';
import { Route, Routes } from "react-router-dom";
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import NotFound from './components/not-found/NotFound';
import { isProdMode } from './util/AppSettings';
import { AuthProvider } from './components/auth/AuthContext';
import HealthCheck from './components/health/HealthCheck';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/health" element={<HealthCheck />} />
        {!isProdMode && <Route path="/login" element={<Login />} />}
        {!isProdMode && <Route path="/register" element={<Register />} />}
        <Route path="*" element={ <NotFound /> } />
      </Routes>
    </AuthProvider>
  );
}

export default App;
